var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "matkp" }, [
    _c(
      "div",
      { staticClass: "f-col fill-height" },
      [
        _c("matkp-nav", { attrs: { glass: "" } }),
        _c("matkp-hero"),
        _c("div", { staticClass: "mat-body f-col align-h-center" }, [
          _c("div", { staticClass: "f-col", staticStyle: { gap: "30px" } }, [
            _c(
              "div",
              {
                staticClass: "f-row align-v-bottom",
                staticStyle: {
                  padding: "50px 0 10px",
                  background: "none",
                  gap: "50px",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "hero-label f-row align-v-center align-h-center no-events",
                  },
                  [
                    _vm._m(0),
                    _c(
                      "div",
                      { staticClass: "f-col", staticStyle: { gap: "20px" } },
                      [
                        _c("div", { staticClass: "tagline" }, [
                          _vm._v(
                            " An open community portal for adipose biology across multiple mammalian species "
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "f-col align-v-center yes-events",
                            staticStyle: {
                              "z-index": "1",
                              gap: "20px",
                              height: "100%",
                            },
                          },
                          [_c("matkp-search")],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "f-row align-v-center",
              staticStyle: { gap: "50px" },
            },
            [
              _vm._m(1),
              _vm.$parent.newsFeed && _vm.$parent.newsFeed.length > 0
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        width: "350px",
                        padding: "20px",
                        background: "#ffffff70",
                        "backdrop-filter": "blur(5px)",
                      },
                    },
                    [
                      _c("div", { staticClass: "news-items" }, [
                        _c("div", { staticClass: "news-item f-row" }, [
                          _c("div", {
                            staticClass: "news-thumbnail contain",
                            staticStyle: {
                              height: "70px",
                              "min-width": "100px",
                            },
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$parent.newsFeed[0].field_thumbnail_image
                              ),
                            },
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "f-col align-v-bottom",
                              staticStyle: { width: "200px" },
                            },
                            [
                              _c("div", { staticClass: "bold" }, [
                                _vm._v(_vm._s(_vm.$parent.newsFeed[0].title)),
                              ]),
                              _c(
                                "a",
                                {
                                  staticClass: "yes-events",
                                  attrs: {
                                    href: `${_vm.$parent.content.news.newsItemUrl}${_vm.$parent.newsFeed[0].nid}`,
                                  },
                                },
                                [_vm._v(" Read more ")]
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]
          ),
          _c(
            "div",
            { staticClass: "section f-row", staticStyle: { gap: "50px" } },
            [_vm._m(2), _c("matkp-anatomogram")],
            1
          ),
          _vm._m(3),
          _c(
            "div",
            { staticClass: "section f-row", staticStyle: { gap: "20px" } },
            [
              _c(
                "div",
                {
                  staticClass: "f-col",
                  staticStyle: { flex: "1", gap: "10px" },
                },
                [
                  _c("div", { staticClass: "section-title" }, [
                    _vm._v("Collaborate"),
                  ]),
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(_vm.$parent.content.collaborate),
                    },
                  }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "f-col",
                  staticStyle: { flex: "1", gap: "10px" },
                },
                [
                  _c("div", { staticClass: "section-title" }, [_vm._v("News")]),
                  _vm.$parent.newsFeed
                    ? _c(
                        "div",
                        { staticClass: "news-items" },
                        [
                          _vm._l(_vm.$parent.newsFeed, function (item) {
                            return _c(
                              "div",
                              { staticClass: "news-item f-row" },
                              [
                                _c("div", {
                                  staticClass: "news-thumbnail contain",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      item.field_thumbnail_image
                                    ),
                                  },
                                }),
                                _c("div", { staticClass: "f-col" }, [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: `${_vm.$parent.content.news.newsItemUrl}${item.nid}`,
                                      },
                                    },
                                    [
                                      _c("div", {}, [
                                        _vm._v(_vm._s(item.title)),
                                      ]),
                                    ]
                                  ),
                                  _c("div", {
                                    domProps: { innerHTML: _vm._s(item.body) },
                                  }),
                                ]),
                              ]
                            )
                          }),
                          _c(
                            "a",
                            {
                              staticStyle: { "align-self": "flex-end" },
                              attrs: {
                                href: `${_vm.$parent.content.news.newsUrl}`,
                              },
                            },
                            [_vm._v("See All News")]
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                ]
              ),
            ]
          ),
        ]),
        _c("matkp-footer"),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "logo f-row align-v-center" }, [
      _c("img", {
        attrs: {
          src: "https://hugeampkpncms.org/sites/default/files/users/user32/matkp/matkplll.png",
        },
      }),
      _c(
        "div",
        {
          staticStyle: {
            "font-size": "22px",
            "line-height": "22px",
            "font-weight": "bold",
          },
        },
        [
          _vm._v(" Mammalian"),
          _c("br"),
          _vm._v(" Adipose"),
          _c("br"),
          _vm._v(" Tissue"),
          _c("br"),
          _c(
            "span",
            {
              staticStyle: {
                "font-size": "14px",
                display: "block",
                "font-weight": "normal",
              },
            },
            [_vm._v(" Knowledge Portal ")]
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "f-row fill-width grow-children",
        staticStyle: { gap: "20px", width: "400px" },
      },
      [
        _c("div", { staticClass: "f-col", staticStyle: { gap: "5px" } }, [
          _c(
            "div",
            { staticStyle: { "font-size": "16px", "font-weight": "bold" } },
            [_vm._v("Browse Datasets")]
          ),
          _c(
            "a",
            {
              staticClass: "yes-events",
              attrs: { href: "/datasets.html?data_type=single_cell" },
            },
            [_vm._v("Single cell RNA-seq")]
          ),
          _c(
            "a",
            {
              staticClass: "yes-events",
              attrs: { href: "/datasets.html?data_type=bulk_rna" },
            },
            [_vm._v("Bulk RNA-seq")]
          ),
        ]),
        _c("div", { staticClass: "f-col", staticStyle: { gap: "5px" } }, [
          _c(
            "div",
            { staticStyle: { "font-size": "16px", "font-weight": "bold" } },
            [_vm._v("Explore Data")]
          ),
          _c("div", { staticClass: "f-row", staticStyle: { gap: "5px" } }, [
            _c(
              "a",
              {
                staticClass: "yes-events",
                attrs: {
                  href: "/cellbrowser.html?dataset=SingleCell_Emont2022_Humans_SCP1376_SN_SAT",
                },
              },
              [_vm._v("Single cell ")]
            ),
            _vm._v(" Emont, M. (2022) "),
          ]),
          _c("div", { staticClass: "f-row", staticStyle: { gap: "5px" } }, [
            _c(
              "a",
              {
                staticClass: "yes-events",
                attrs: {
                  href: "/bulkbrowser.html?dataset=bulkRNA_Emont2022_Humans_SAT",
                },
              },
              [_vm._v("Bulk")]
            ),
            _vm._v(" Emont, M. (2022) "),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "f-col", staticStyle: { gap: "20px" } }, [
      _c("div", { staticClass: "section-title" }, [
        _vm._v("Where is adipose tissue found?"),
      ]),
      _c("div", [
        _vm._v(
          " Adipose tissue (AT) is found throughout the body in aggregates referred to as depots. Broadly speaking, AT can be divided into subcutaneous depots (found under the skin) and visceral depots (found in the peritoneal and thoracic cavities). There are, however, other depots that are not clearly categorized as subcutaneous or visceral, such as around blood vessels (perivascular), within joints (intra-articular), and in bone marrow. "
        ),
        _c("a", { attrs: { href: "/info.html?page=adipose" } }, [
          _vm._v("Learn more."),
        ]),
      ]),
      _c("em", [
        _vm._v(
          " Select a species to the right, then mouse over the depots lists for more information. "
        ),
      ]),
      _c("em", [_vm._v("Click on depots to see (N) associated datasets. ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "section f-col" }, [
      _c(
        "div",
        {
          staticClass: "f-col",
          staticStyle: { "font-size": "14px", gap: "20px" },
        },
        [
          _c("div", { staticClass: "section-title" }, [
            _vm._v("Why study adipose tissue?"),
          ]),
          _c(
            "div",
            { staticStyle: { "column-count": "3", "column-gap": "20px" } },
            [
              _vm._v(
                " Once thought to be an inert, cellularly bland storage reservoir for energy in the form of triacylglycerol, adipose tissue (AT) is now recognized to be a multifocal, complex, heterogenous, and dynamic organ that controls metabolic homeostasis via both endocrine and paracrine mechanisms. Unlike most organs, AT is "
              ),
              _c("span", { staticClass: "bold" }, [_vm._v("multifocal")]),
              _vm._v(
                ", found both in discrete depots as well as scattered throughout other organs and tissues. AT is "
              ),
              _c("span", { staticClass: "bold" }, [_vm._v("complex")]),
              _vm._v(
                " because it contains a multitude of cell types and subtypes, including adipocytes, fibroblastic stromal cells (many of which represent adipocyte progenitor cells), immune cells, Schwann cells, and vascular cells. AT is "
              ),
              _c("span", { staticClass: "bold" }, [_vm._v("heterogeneous")]),
              _vm._v(
                " because its cellular composition (and therefore its function) varies significantly between, and also within depots. AT is "
              ),
              _c("span", { staticClass: "bold" }, [_vm._v("dynamic")]),
              _vm._v(
                " because its composition changes during development or after changes in nutrition, physical activity, or ambient temperature. "
              ),
              _c("br"),
              _c("br"),
              _vm._v(
                " Dysfunctional AT is associated with a variety of cardiometabolic diseases. An obvious example is obesity, a condition in which excess adiposity significantly increases the risk of type 2 diabetes (T2D), metabolic dysfunction-associated steatotic liver disease (MASLD), and cardiovascular disease. But AT is not only associated with disease when in excess; lack of AT, either generally or limited to specific regions, also confers risk for severe T2D, MASLD, and atherosclerosis. Individuals with either inherited or acquired forms of lipodystrophy store excess lipid in non-adipose organs such as liver, muscle, and pancreatic islets, contributing to insulin resistance and reduced insulin secretion. "
              ),
            ]
          ),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }